const vat_rates = {
  name: 'vat_rates',
  title: 'Ставка НДС',
  url: '/refs/vat_rates',
  include: [],
  filterBy: ['title'],
  perms: {
    list: 'v1.vat_rates.list',
    read: 'v1.vat_rates.read',
    create: 'v1.vat_rates.create',
    update: 'v1.vat_rates.update',
    delete: 'v1.vat_rates.delete',
  },
  listColumns: [
    {
      name: 'title',
      required: true,
      label: 'Наименование',
      align: 'left',
      field: 'title',
      sortable: true,
    },
    {
      name: 'size',
      required: true,
      label: 'Размер ставки',
      align: 'left',
      field: 'size',
      sortable: true,
    },
    {
      name: 'description',
      required: false ,
      label: 'Описание',
      align: 'left',
      field: 'description',
      sortable: true,
    },
  ],
  editColumns: [
    {
      name: 'title',
      required: true,
      label: 'Наименование',
      align: 'left',
      field: 'title',
      sortable: true,
    },
    {
      name: 'size',
      required: true,
      label: 'Размер ставки',
      align: 'left',
      field: 'size',
      sortable: true,
    },
    {
      name: 'description',
      required: false ,
      label: 'Описание',
      align: 'left',
      field: 'description',
      sortable: true,
    },
  ],
};

export {
  vat_rates,
};
